@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

html,
body,
#root {
  margin: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  font-family: 'Inter', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif, sans-serif;
}

.App-light {
  color: #fcfcfc;
}

.App-dark {
  color: #232127;
}

p {
  margin: 0;
}

button {
  margin-right: 0.5rem;
  color: white;
  font-family: 'Gloria Hallelujah', sans-serif;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
}

@keyframes scalePulse {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.loading-logo {
  animation: scalePulse 1000ms ease-in-out infinite alternate;
}

#loading-img {
  animation: rotation 4s infinite ease;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(100deg);
  }
  60% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.contact {
  width: 440px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .image {
    width: 200px;
    height: 200px;
  }
}

.hidden,
.hidden * {
  pointer-events: none;
  opacity: 0;
  * {
    pointer-events: none !important;
  }
}
.threed-button {
  display: flex;
  flex-flow: column;
  justify-content: center;
  transition: all 333ms;
  align-items: center;
}

.threed-button.hidden {
  opacity: 0.5;
  pointer-events: none;
}

.threed-button.showing {
  opacity: 1;
  pointer-events: auto;
}
*::-webkit-scrollbar {
  width: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
}
